import './about.css'
import prem from '../images/prem.png'
import AOS from 'aos'
import { useEffect } from 'react'
import 'aos/dist/aos.css'

const About = () => {
   useEffect(() => {
          AOS.init({duration:2000})
   },[])

  return (
    <div className='w-100 about' id='about'>
        <div className='container'>
                    <div className="row pt-5 ">
                    <div className='col-12 col-md-6 col-lg-6 aboutImagePart' data-aos='fade-right'>
                        <img src={prem} className='abtImg' alt="prem" />
                    </div>
                    <div className='col-12 col-md-6 col-lg-6 text-white aboutTextPart' data-aos='fade-left'>
                        <div>
                        <h3>
                            Hello, I am Prem Kumar
                        </h3>

                        <p>
                            I am passionate and extremely motivated new front-end developer.
                            I believe I stand out due to my passion for continuous innovation and pushing boundaries
                            bring forward new ideas. with my skills, I identify real-world challenges and develop applications
                            that bring efficiency and improvement to the lives of users and also showcase which technical improvements
                            can make a meaningful impact on the user and the business.  
                        </p>

                        <table>
                            <tbody>
                                <tr>
                                    <td className='pe-5'>Name</td>
                                    <td>: Prem Kumar</td>
                                </tr>
                                <tr>
                                    <td className='pe-5'>Age</td>
                                    <td>: 26</td>
                                </tr>
                                <tr>
                                    <td className='pe-5'>Email</td>
                                    <td>: premkumarsince1996@gmail.com</td>
                                </tr>
                                <tr>
                                    <td className='pe-5'>Phone</td>
                                    <td>: 9345046458</td>
                                </tr>
                                <tr>
                                    <td className='pe-5'>Address</td>
                                    <td>: West tambaram, Chennai</td>
                                </tr>
                            </tbody>
                        </table>

                        <button className='btn downloadcv mt-4'>Download CV</button>
                    </div>
                </div>
        </div>

        </div>
        
    </div>
  )
}

export default About