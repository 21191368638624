import './projects.css'
import gym from '../images/gym.png'
import blog from '../images/blogapp.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

const Service = () => {
  useEffect(()=> {

   AOS.init({duration:2000})

  },[])


  return (
    <div className='projects p-5' id='projects'>
        <div className='myprojectText pt-5' >
            <h3>MY <span>PROJECTS</span></h3><br />
            <hr />
        </div>

        <div className="container text-center mt-5" >
            <div className="row">
                <div className='col-12 col-md-6 col-lg-4 p-4' data-aos='fade-right'>
                     <div className="card text-dark ">
                        <img src={gym} className='card-img-top'alt="" />
                        <div className='card-body'>
                            <h5 className='card-title'>GYM APP</h5>
                            <p>DESC</p>
                            <a href="" className='btn'>More details</a>
                        </div>
                     </div>
                </div>

                <div className='col-12 col-md-6 col-lg-4 p-4' data-aos='fade-right'>
                     <div className="card text-dark ">
                        <img src={blog} className='card-img-top'alt="" />
                        <div className='card-body'>
                            <h5 className='card-title'>Blog APP</h5>
                            <p>DESC</p>
                            <a href="" className='btn'>More details</a>
                        </div>
                     </div>
                </div>

                <div className='col-12 col-md-6 col-lg-4 p-4' data-aos='fade-right'>
                     <div className="card text-dark ">
                        <img src={gym} className='card-img-top'alt="" />
                        <div className='card-body'>
                            <h5 className='card-title'>GYM APP</h5>
                            <p>DESC</p>
                            <a href="" className='btn'>More details</a>
                        </div>
                     </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default Service