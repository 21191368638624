import './contact.css'

const Contact = () => {
  return (
    <div className='contact text-center text-white' id='contact'>
        <br />
        <h1>
            contact
        </h1>
        <br />
        <hr />
        <div className='mx-md-auto text-center container'>
            <form action="">
                <div className='form-group mt-2'>
                   <label htmlFor="Uname">User Name:</label>
                   <input type="text" className='form-control' id='uname' placeholder='enter name'/>
                </div>

                <div className='form-group mt-2'>
                   <label htmlFor="email">Email:</label>
                   <input type="email" className='form-control' id='email' placeholder='enter email'/>
                </div>
               
                <div className='form-group mt-2'>
                   <label htmlFor="Uname">Message:</label>
                   <textarea className='form-control' placeholder='message..' col='30' rows="10"></textarea>
                </div>

                <button type='button' className='btn mt-4 mb-4'>Message Me</button>
                
            </form>
        </div>
    </div>
  )
}

export default Contact